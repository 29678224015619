import { useEffect, useState } from 'react';
import './App.scss';
import Routes from "./containers/Routes"
import NavBar from "./components/NavBar"

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { gql } from '@apollo/client';

const client = new ApolloClient({
  uri: 'https://videogames-api-graphql.herokuapp.com/graphql',
  cache: new InMemoryCache()
});



function App() {
  const [games, setGames] = useState([])

  client
    .query({
      query: gql`
    {
      games{
        id
        name
        year
        developer
        category
        mode
        serie
        urlImg
      }
    }
    `
    })
    .then(result => setGames(result.data.games));


  // const grabGames = () => {
  //   fetch(`https://the-videogames-api.herokuapp.com/games`)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setGames(res)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   grabGames()
  // }, [])

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <NavBar />
        <Routes games={games} />
      </div>
    </ApolloProvider>
  );
}

export default App;
