import React, { useState } from "react";
import styles from "./Dashboard.module.scss";
import CardList from "../../components/CardList";
import { gql, useQuery } from "@apollo/client";

const Dashboard = (props) => {
  const { games } = props;
  const [graphKey, setGraphKey] = useState("name");
  const [graphValue, setGraphValue] = useState(`""`);
  const [isYear, setIsYear] = useState("");

  const paramasArray = ["name", "year", "developer", "category", "serie"];

  const getGames = gql`
{
  gamesFilter(${graphKey}: ${graphValue}){
    id
    name
    year
    developer
    category
    mode
    serie
    urlImg
  }
}
`;

  const opts = paramasArray.map((param) => (
    <option key={param} value={param}>
      {param}
    </option>
  ));

  const { loading, error, data } = useQuery(getGames);

  if (loading)
    return (
      <section className={styles.dashboard}>
        <div className={styles.ldsRoller}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p>Loading...</p>
      </section>
    );
  if (error) return `Error! ${error.message}`;

  return (
    <section className={styles.dashboard}>
      <h1>Games Cards</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setGraphKey(e.target.params.value);
          if (e.target.params.value !== "year") {
            setGraphValue(`"${e.target.nome.value}"`);
          } else {
            setGraphValue(e.target.nome.value);
          }
        }}
      >
        <select
          name="params"
          id="params"
          onChange={(e) => setIsYear(e.target.value)}
        >
          {opts}
        </select>
        <input
          placeholder="Text here"
          id="nome"
          type={isYear == "year" ? "number" : "text"}
        />
        <input type="submit" value="Search" />
      </form>
      <br />
      <CardList games={graphValue ? data.gamesFilter : games} />
    </section>
  );
};

export default Dashboard;
